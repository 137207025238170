<template>
<div class="main">
  <p>
    <b>{{$t('myprofile.settings.settings')}}</b>
  </p>
  <p>
    <img src="/assets/icons/white/position_black.png">✔ - {{$t('myprofile.settings.centered')}}
  </p>
  <p>
    <img src="/assets/icons/white/redirect_to_site.png">✔ - {{$t('myprofile.settings.open_site')}}
  </p>
</div>
</template>

<script>
export default {
  name: "settings"
}
</script>

<style scoped>

</style>
