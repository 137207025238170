<script>
import Compressor from 'compressorjs';
import bcrypt from 'bcryptjs';
import tg from '../telegram/tg'
import FileUpload from 'primevue/fileupload/FileUpload'

export default {
  components:{FileUpload},
  data(){
    return{
      me:localStorage.mapa_me,
      lat:'',
      lng:'',
      online:'',
      img:'',
      selectedFile:null,
      image:'',
      status:'',
      s:localStorage.mapa_api,
      page:'',
      // openHot:localStorage.getItem("openHot")=="0"?false:true,
    }
  },
  mounted(){
    this.initProfile();
  },
  methods:{
    async initProfile(){

        this.$http.post(this.s+'/app/myprofile/', {me:this.me}).then(async (res) => {
          this.status = res.data.status;
          this.page = res.data.page;
          if(localStorage.mapa_login === 'site'){
            this.img = localStorage.mapa_api+res.data.image;
          } else if(localStorage.mapa_login === 'tg'){
            this.img = await tg.getUserPhoto(await tg.getMe());
          }
        });
    },
    onChange (image) {
      console.log('New picture selected!');
      if (image) {
        console.log('Picture loaded.');
        this.image = image
      } else {
        console.log('FileReader API not supported: use the <form>, Luke!')
      }
    },
    onFileSelected(event){
      console.log(event)
      this.image=event.target.files[0];
    },
    upImg(event){
      let t=this
      new Compressor(event.files[0], {
        quality: 0.6,
        success(result) {
          const fd=new FormData();
          fd.append('image',result, result.name);
          fd.append('me',t.me);
          t.$http.post(t.s+'/app/upImg/', fd).then((response) => {
            t.img=t.s+response.data;
          });
        },
        error(err) {
          console.log(err.message);
        },
      });
      // const fd=new FormData();
      // fd.append('image',this.image);
      // fd.append('me',this.me);
      // this.$http.post(this.s+'/app/upImg/', fd)
      //   .then((response) => {
      //   console.log(response.data)
      //     this.img=this.s+response.data;
      // });
    },

    upStatus() {
      console.log(this.status)
      this.$http.post(this.s+'/app/upStatus/', {me:this.me, status:this.status})
      localStorage.mapa_status = this.status
    },

    upPage() {
      this.$http.post(this.s+'/app/upPage/', {me:this.me, page:this.page})
    },

    deletePhoto() {
      if (this.img!=localStorage.getItem('simg')+'/images/default.jpg') {
        if (confirm('Удалить фото?')) {
          this.$http.post(localStorage.mapa_api+'/app/delImg/', {me:this.me})
            .then((response) => {
              this.img = this.s + response.data;
            });
        }
        else {
        }
      }
    },

    deleteUser(){
      let t=this;
      let pas=prompt('Вы действительно хотите удалить юзера '+t.me+'?\n'+
        'Все переписки с вашим участием будут удалены!\nТакже вы будете удалены из избранных у других юзеров!\n' +
        'Для удаления введите пароль и жмите "OK"!');
      if(pas!=null){
        t.$http.post(localStorage.mapa_api + '/app/login/', {username: t.me}).then((response) => {
          bcrypt.compare(pas, response.data, function(err, result) {
            if (result==true){
              t.$http.post(localStorage.mapa_api+'/app/delUser/', {me:t.me}).then((response) => {
                localStorage.removeItem("mapa_me");
                localStorage.removeItem("mapa_state");
                localStorage.removeItem("zoom");
                localStorage.removeItem("lat");
                localStorage.removeItem("lng");
                localStorage.removeItem("mapa_mypos");
                document.location.reload(true);
              });
            }
            else{alert('Неверный пароль!')}
          });
        });
      }
    },
    // upOpenHot(){
    //   localStorage.getItem("openHot")=="0"?localStorage.setItem("openHot","1"):localStorage.setItem("openHot","0")
    // }
  }
}
</script>

<template>
  <div class="main">
    <div class="container general">
      <div class="row">
        <div class="col-sm-12">
          <img class="ava" :src="img">
        </div>
      </div>
      <h1>{{me}}</h1>
      <a :href="'https://t.me/'+me" target="_blank">telegram</a>
      <div class="my-p myprofile_input">
        <div class="p-float-label">
          <InputText v-model="status"/>
          <label>{{$t('myprofile.status')}}</label>
        </div>
        <Button @click="upStatus">√</Button>
      </div>
      <div class="my-p myprofile_input">
        <div class="p-float-label">
          <InputText v-model="page" :title="$t('myprofile.site_title')"/>
          <label>{{$t('myprofile.site')}}</label>
        </div>
        <Button @click="upPage">√</Button>
      </div>
<!--      <div class="my-p">-->
<!--        <FileUpload mode="basic" name="demo[]" accept="image/*" :maxFileSize="1000000" :customUpload="true" @uploader="upImg" :chooseLabel="$t('ava')"/>-->
<!--      </div>-->
      <div class="my-p">
        <Button class="p-button-danger" @click="deleteUser">{{$t('myprofile.delete_user')}}</Button>
<!--        <Button class="p-button-secondary" @click="deletePhoto">{{$t('delete_ava')}}</Button>-->
      </div>
    </div>
  </div>
</template>

<style scoped>
.myprofile_input{
  display: flex;
  justify-content: center;
}
.row{
  display: block;
}
h1, h2 {
  text-align: center;
}
.ava{
  height: 300px;
  width: auto;
}
</style>
