<template>
  <div class="main">
    <div class="container">
<!--      <form>-->
<!--        <div class="my-p">-->
<!--          <div class="p-float-label">-->
<!--            <InputText :class="{'p-invalid': v$.nick.$invalid && sub}" v-model="v$.nick.$model" autocomplete="username"/>-->
<!--            <label>{{$t('auth.nick')}}</label>-->
<!--          </div>-->
<!--          <small v-if="(v$.nick.$invalid) && sub || v$.nick.$pending.$response" class="p-error">{{$t('auth.valid.nick')}}</small>-->
<!--        </div>-->
<!--        <div class="my-p">-->
<!--          <div class="p-float-label">-->
<!--            <Password :class="{'p-invalid': v$.pass.$invalid && sub}" v-model="v$.pass.$model" :feedback="feedback" @keyup.enter="login" autocomplete="current-password"/>-->
<!--            <label>{{$t('auth.pass')}}</label>-->
<!--          </div>-->
<!--          <small v-if="(v$.pass.$invalid) && sub || v$.pass.$pending.$response" class="p-error">{{$t('auth.valid.pass')}}</small>-->
<!--        </div>-->
<!--        <div v-if="value==='reg'" class="my-p">-->
<!--          <div class="p-float-label">-->
<!--            <Password :class="{'p-invalid': v$.confirm.$invalid && sub}" v-model="v$.confirm.$model" :feedback="false" @keyup.enter="reg"/>-->
<!--            <label>{{$t('auth.confirm')}}</label>-->
<!--          </div>-->
<!--          <small v-if="(v$.confirm.$invalid) && sub || v$.confirm.$pending.$response" class="p-error">{{$t('auth.valid.confirm')}}</small>-->
<!--        </div>-->
<!--        <div class="my-p">-->
<!--          <Button v-if="value==='login'" class="p-button-rounded" @click="login">{{ $t('auth.login') }}</Button>-->
<!--          <Button v-if="value==='reg'" class="p-button-rounded" @click="reg">{{ $t('auth.reg') }}</Button>-->
<!--        </div>-->
<!--      </form>-->
      <!--            <vue-telegram-login mode="callback" telegram-login="MapaWSBot" @callback="yourCallbackFunction"/>-->
      <!--            <button class="btn btn-primary btn-orange button" @click="telegram">telegram</button>-->
      <!--            <form>-->
      <!--              <div id="phoneDiv">-->
      <!--                <label>phone number</label> <input disabled type="text" id="phone" />-->
      <!--                <button type="button" disabled id="phoneSend">Send</button>-->
      <!--              </div>-->
      <!--              <div id="codeDiv" style="visibility: hidden">-->
      <!--                <label>code you recieved</label> <input type="text" id="code" />-->
      <!--                <button type="button" id="codeSend">Send</button>-->
      <!--              </div>-->
      <!--              <div id="passDiv" style="visibility: hidden">-->
      <!--                <label>password</label> <input type="text" id="pass" />-->
      <!--                <button type="button" id="passSend">Send</button>-->
      <!--              </div>-->
      <!--            </form>-->
      <div class="my-p" v-if="state==='start'">
        <button v-if="value==='login'" class="tg_login_button" @click="signInTelegram">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0,0,256,256">
            <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.33333,5.33333)"><path d="M24,4c-11.04569,0 -20,8.95431 -20,20c0,11.04569 8.95431,20 20,20c11.04569,0 20,-8.95431 20,-20c0,-11.04569 -8.95431,-20 -20,-20z" fill-opacity="0" fill="#fdfefe"></path><path d="M33.95,15l-3.746,19.126c0,0 -0.161,0.874 -1.245,0.874c-0.576,0 -0.873,-0.274 -0.873,-0.274l-8.114,-6.733l-3.97,-2.001l-5.095,-1.355c0,0 -0.907,-0.262 -0.907,-1.012c0,-0.625 0.933,-0.923 0.933,-0.923l21.316,-8.468c-0.001,-0.001 0.651,-0.235 1.126,-0.234c0.292,0 0.625,0.125 0.625,0.5c0,0.25 -0.05,0.5 -0.05,0.5z" fill="#ffffff"></path><path d="M23,30.505l-3.426,3.374c0,0 -0.149,0.115 -0.348,0.12c-0.069,0.002 -0.143,-0.009 -0.219,-0.043l0.964,-5.965z" fill="#b0bec5"></path><path d="M29.897,18.196c-0.169,-0.22 -0.481,-0.26 -0.701,-0.093l-13.196,7.897c0,0 2.106,5.892 2.427,6.912c0.322,1.021 0.58,1.045 0.58,1.045l0.964,-5.965l9.832,-9.096c0.22,-0.167 0.261,-0.48 0.094,-0.7z" fill="#cfd8dc"></path></g></g>
          </svg>
          <div>{{ $t('auth.tg.login_button') }}</div>
        </button>
      </div>

      <div v-if="state==='loading'" class="">
        <ProgressSpinner />
      </div>

      <div v-show="state==='phone'">
        <div class="my-p">
          <label class="input_label">{{$t('auth.tg.phone')}}</label>
          <InputText class="input_login p-inputtext-lg" id="phone" :class="{'p-invalid': v$.phone_tg.$invalid && sub || error === true}" v-model="v$.phone_tg.$model"/>
          <div>
            <Button class="p-button-rounded" id="phoneSend">{{ $t('auth.send') }}</Button>
          </div>
        </div>
      </div>

      <div v-show="state==='code'">
        <div class="my-p">
          <label class="input_label">{{$t('auth.tg.code')}}</label>
          <InputText class="input_login p-inputtext-lg" id="code" :class="{'p-invalid': v$.code_tg.$invalid && sub || error === true}" v-model="v$.code_tg.$model"/>
          <div>
            <Button class="p-button-rounded send_button" id="codeSend">{{ $t('auth.send') }}</Button>
          </div>
        </div>
      </div>

      <div v-show="state==='pass'">
        <div class="my-p">
          <label class="input_label">{{$t('auth.pass')}}</label>
          <InputText class="input_login p-inputtext-lg" id="pass" :class="{'p-invalid': v$.pass_tg.$invalid && sub || error === true}" v-model="v$.pass_tg.$model"/>
          <div>
            <Button class="p-button-rounded send_button" id="passSend">{{ $t('auth.send') }}</Button>
          </div>
        </div>
      </div>

      <Dialog :header="$t('groups.attention')" :visible.sync="dialog" :modal="false">
        {{$t('auth.dialog')}}
        <template #footer>
          <Button label="ОК" autofocus @click="dialog=false"/>
        </template>
      </Dialog>

    </div>
  </div>
</template>

<style scoped>
.input_label{
  color: var(--text-color);
}
.input_login{
  margin: 10px 0;
}
/*.send_button{*/
/*  margin-top: 10px;*/
/*}*/
.btn_tg{
  padding-bottom: 20px;
}
.my-p{
  padding: 15px 0;
  display: flex!important;
  flex-direction: column;
}
.main {
  /*margin-top: 50px;*/
  /*text-align: left;*/
  display: flex;
  align-items: center; /* Выравнивание по центру по вертикали */
  justify-content: center; /* Выравнивание по центру по горизонтали */
  height: calc(100% - 100px); /* Занимает всю высоту страницы */
  /*max-width: 1200px;*/
}
.container{
  margin: 0 auto;
  /*text-align: center;*/
  /*width: 230px;*/
}
.tg_login_button {
  font-size: 16px;
  line-height: 20px;
  padding: 5px 11px 5px 5px;
  display: flex;
  align-items: center;
  vertical-align: top;
  font-weight: 500;
  border-radius: 17px;
  background-color: #54a9eb;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 auto;
  border: none;
  color: #fff;
  cursor: pointer;
}
</style>

<script>
//import {vueTelegramLogin} from 'vue-telegram-login'
//import "../../plugins/telegram3.js"
//import {telegram} from "../../plugins/telegram3";
//import {TelegramClient, sessions} from "../../plugins/telegram.js"
import bcrypt from 'bcryptjs'
import tg from "../telegram/tg"
import InputNumber from "primevue/inputnumber/InputNumber"
import InputMask from "primevue/inputmask/InputMask"
import Password from "primevue/password/Password"
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import ProgressSpinner from "primevue/progressspinner"
import {bus} from '../eventBus'
import methods from "../telegram/methods";
import api from "../telegram/api";
import Dialog from 'primevue/dialog';


export default {
  components:{InputNumber, InputMask, Password, ProgressSpinner, Dialog},
  setup(){
    return {v$:useVuelidate()}
  },
  validations(){
    return{
      nick:{required, maxLength: maxLength(30)},
      pass:{required, minLength: minLength(8)},
      confirm:{required, s(){return this.pass===this.confirm}},

      phone_tg:{required, minLength: minLength(10)},
      code_tg:{required, minLength: minLength(5)},
      pass_tg:{required, minLength: minLength(4)},
    }
  },
  props:{
    value:[String],
  },
  data(){
    return{
      tg_input:'',
      nick:'',
      pass:'',
      confirm:'',
      sub:false,
      state:'start',
      loading:false,
      phone_tg:null,
      code_tg:null,
      pass_tg:null,
      error:false,
      dialog:true,
    }
  },
  computed:{
    feedback(){
      if(this.value === 'login') return false
      else return true
    }
  },
  mounted() {
    bus.$on('loading', ()=>{this.state = 'loading'})
    bus.$on('pass', ()=>{this.state = 'pass'})
    bus.$on('code', ()=>{this.state = 'code'})
    bus.$on('error', () => {
      let count = 0;
      const blinkError = () => {
        if (count < 3) {
          this.error = true; // Устанавливаем красную обводку
          setTimeout(() => {
            this.error = false; // Убираем обводку
            count++;
            setTimeout(blinkError, 100); // Рекурсия с задержкой
          }, 100);
        }
      };
      blinkError(); // Запускаем "мигание"
    });
  },
  methods:{

    yourCallbackFunction (user) {console.log(user)},
    login() {
      let t = this
      t.sub=true
      if(!t.v$.nick.$invalid && !t.v$.pass.$invalid){
        t.$http.post(localStorage.mapa_api + '/app/login/', {username: t.nick}).then((response) => {
          if (response.data != 'error') {
            bcrypt.compare(t.pass, response.data, function (err, result) {
              if (result == true) {
                localStorage.mapa_me=t.nick
                localStorage.mapa_mypos='true'
                localStorage.mapa_login='site'
                document.location.reload(true);
              } else {
                alert('Неверные данные!')
              }
            });
          } else {
            alert('Неверные данные!')
          }
        });
      }
    },
    reg(){
      let t = this;
      t.sub=true
      if(!t.v$.nick.$invalid && !t.v$.pass.$invalid && !t.v$.confirm.$invalid){
        bcrypt.hash(this.pass, 10, function(err, hash) {
          t.$http.post(localStorage.mapa_api+'/app/reg/', {username:t.nick, password:hash}).then((response) => {
            if (response.data!='error'){
              // console.log(response.data)
              localStorage.mapa_me=response.data;
              localStorage.mapa_mypos='true'
              localStorage.mapa_login = 'site'
              document.location.reload(true);
            }
            else{
              alert('Такой ник уже есть, придумай другой!')
            }
          });
        });
      }
    },
    async signInTelegram() {
      const t = this
      // await tg.signInTelegram()


      const phoneSend = document.getElementById("phoneSend");
      const codeSend = document.getElementById("codeSend");
      const passSend = document.getElementById("passSend");
      function phoneCallback() {
        return new Promise((resolve) => {
          phoneSend.addEventListener("click", function () {
            t.sub = true
            if(!t.v$.phone_tg.$invalid){
              resolve(t.phone_tg);
            }
          });
        });
      }
      function codeCallback() {
        return new Promise((resolve) => {
          codeSend.addEventListener("click", function () {
            t.sub = true
            if(!t.v$.code_tg.$invalid){
              resolve(t.code_tg);
            }
          });
        });
      }
      function passwordCallback() {
        return new Promise((resolve) => {
          passSend.addEventListener("click", function () {
            t.sub = true
            if(!t.v$.pass_tg.$invalid){
              resolve(t.pass_tg);
            }
          });
        });
      }
      function blinkError(count){
        let counted = 0;
        const blinkError = () => {
          if (counted < count) {
            t.error = true; // Устанавливаем красную обводку
            setTimeout(() => {
              t.error = false; // Убираем обводку
              counted++;
              setTimeout(blinkError, 100); // Рекурсия с задержкой
            }, 100);
          }
        };
        blinkError(); // Запускаем "мигание"
      }

      let user = await tg.getMe();
      console.log(user)

      if (!user) {
        async function getPhone(){
          t.state='phone'
          t.sub = false
          let success = false
          let my_phone, phone_code_hash
          while (!success){
            my_phone = await phoneCallback()
            const res = await methods.sendCode(my_phone);
            if(res!==null){
              phone_code_hash = res.phone_code_hash
              success = true
              return { my_phone, phone_code_hash };
            }else {
              console.log("Ошибка: код не отправлен. Повторяем попытку...")
              blinkError(10)
            }
          }
        }
        async function getCode(my_phone, phone_code_hash){
          t.state = 'code'
          t.sub = false
          let success = false
          let code
          while (!success){
            code = await codeCallback()
            // const res = await methods.sendCode(my_phone);
            try {
              const signInResult = await methods.signIn({my_phone, code, phone_code_hash});
              success = true
              t.state = 'loading'
              if (signInResult._ === 'auth.authorizationSignUpRequired') {
                console.log("Ошибка: этот номер не зарегестрирован, пройдите регистрацию на приложении телеграмм.")
                t.state = 'phone'
                // await methods.signUp({my_phone, phone_code_hash});
              }
            } catch (error) {
              console.log(error)
              if (error.error_message === 'SESSION_PASSWORD_NEEDED') {
                // 2FA
                success = true
                await getPass()
              }else{
                console.log("Ошибка: не верный код. Повторяем попытку...")
                blinkError(10)
              }
            }
          }
        }
        async function getPass(){
          t.state = 'pass'
          t.sub = false
          let success = false
          let password
          while (!success){
            password = await passwordCallback()
            const {srp_id, current_algo, srp_B} = await methods.getPassword();
            const {g, p, salt1, salt2} = current_algo;
            const {A, M1} = await api.crypto.getSRPParams({g, p, salt1, salt2, gB: srp_B, password});
            try{
              const checkPasswordResult = await methods.checkPassword({srp_id, A, M1});
              t.state = 'loading'
              console.log(checkPasswordResult)
              success = true
            }catch (e) {
              console.log(e)
              console.log("Ошибка: не верный пароль. Повторяем попытку...")
              blinkError(10)
            }
          }
        }
        const { my_phone, phone_code_hash } = await getPhone();
        await getCode(my_phone, phone_code_hash)

        // try {
        //   bus.$emit('loading')
        //   const signInResult = await methods.signIn({my_phone, code, phone_code_hash});
        //   if (signInResult._ === 'auth.authorizationSignUpRequired') {
        //     console.log("singUp")
        //     await methods.signUp({my_phone, phone_code_hash});
        //   }
        // } catch (error) {
        //   if (error.error_message !== 'SESSION_PASSWORD_NEEDED') {
        //     console.log(error);
        //     return;
        //   }
        //   // 2FA
        //   // const password = '1371';
        //   bus.$emit('pass')
        //   const password = await passwordCallback()
        //   bus.$emit('loading')
        //   const {srp_id, current_algo, srp_B} = await methods.getPassword();
        //   const {g, p, salt1, salt2} = current_algo;
        //   const {A, M1} = await api.crypto.getSRPParams({g, p, salt1, salt2, gB: srp_B, password});
        //   const checkPasswordResult = await methods.checkPassword({srp_id, A, M1});
        // }
      } else {
        console.log('logged')
      }

      user = await tg.getMe()
      if(user){
        this.$http.post(localStorage.mapa_api+'/tg/login/', {username:user.username,id:user.id}).then((res) => {
          console.log(res.data)
          localStorage.mapa_id = res.data.id
          localStorage.mapa_me = user.username
          localStorage.mapa_status = res.data.status_user
          localStorage.mapa_mypos = 'true'
          localStorage.mapa_login = 'tg'
          localStorage.mapa_state = 'tg'
          this.loading = false
          document.location.reload(true);
        })
      }else{
        alert("Ошибка входа")
      }
    }
  }
}
</script>



